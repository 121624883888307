.react-datepicker {
  border: 1px solid #e8e8e8 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  outline: none;
}
.react-datepicker-popper {
  z-index: 200000;
}
.react-datepicker__navigation {
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  margin: 10px;
}

.react-datepicker__triangle {
  display: none;
  border-bottom: none;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
}

.react-datepicker__day-names {
  margin-top: 20px;
  margin-bottom: -10px;
}

.react-datepicker__day-name {
  color: #a9a9a9;
  font-weight: 600;
  text-transform: capitalize;
}

.react-datepicker__day {
  color: #2c2c2c;
}

.react-datepicker__navigation-icon {
  top: 2px;
}

.react-datepicker__current-month {
  margin-top: 10px;
  color: #2c2c2c;
  text-transform: capitalize;
}

.react-datepicker__day--selected:hover {
  background-color: #5e54a9;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: #2c2c2c;
}

.react-datepicker__month {
  background-color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--today,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__day--selecting-range-end:hover,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range,
.react-datepicker__day--range-start {
  background-color: #5e54a9;
  color: #fff;
  border-radius: 4px;
}
.react-datepicker__close-icon::after {
  background-color: #5e54a9;
  color: #fff;
}

.react-datepicker__day--selected {
  background-color: #dc0e91; /* Exemplo: cor azul para o dia selecionado */
  color: #fff;
}
